import {GetMyProfile} from "../api/profile";

const getDefaultAccess = () => ({
    user_id: 0,
    lastname: "",
    firstname: "",
    middlename: "",
})

export const moduleAccess = {
    state: {
        access:
            localStorage.getItem('access') ?
                JSON.parse(localStorage.getItem('access').toString()) :
                getDefaultAccess(),
    },
    mutations: {
        SET_ACCESS(state, access) {
            localStorage.setItem('access', JSON.stringify(access))
            state.access = access
        },
        RESET_STATE(state) {
            localStorage.setItem('access', JSON.stringify(getDefaultAccess()))
            state.access = getDefaultAccess()
        },
    },
    getters: {
        isAuthenticated: state => state.access.user_id > 0,
        USER_ACCESS: state => state.access,
        myId: state => state.access.user_id,
    },
    actions: {
        GET_UPDATE_ACCESS(context) {
            let commit = context.commit
            return new Promise((resolve, reject) => {
                GetMyProfile().then(
                    (access) => {
                        commit('SET_ACCESS', access)
                        resolve()
                    },
                    () => {
                        commit('RESET_STATE')
                    }
                )
            })
        },
    },
}
