<template>
	<footer class="footer">
		<div class="container">
			<div class="footer__text">
				<div class="footer__text-col footer__text-col-1">
					<p>2024 г. Южно-Уральский государственный<br>университет (г. Челябинск)</p>
					<p>При использовании информации ссылка<br>на сайт обязательна.</p>
				</div>
				<div class="footer__text-col footer__text-col-2">
					<p>Науменко Наталья Владимировна, доктор технических наук, профессор кафедры «Пищевые и
						биотехнологии»</p>
					<p>Россия, 454080 Челябинск, проспект Ленина, 76<br>Тел./факс: +7 (351) 267-93-80<br>E-mail:
						Naumenkonv@susu.ru</p>
				</div>
				<div class="footer__text-col footer__text-col-3">
					<p>Проект реализуется победителем грантового конкурса для преподавателей
						магистратуры Стипендиальной программы Владимира Потанина</p>
				</div>
				<div  class="footer__logo">
					<img src="../../../public/assets/logo_sponsor.jpeg" alt="Logo: sponsor" class="footer__logo-sponsor">
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style>
.footer {
	background-color: #242A35;
}

.footer__text {
	padding-top: 20px;
	padding-bottom: 20px;
	display: grid;
	justify-content: space-between;
	width: 100%;
	align-items: flex-start;
	font-size: 12px;
	line-height: 14px;
	color: #FFFFFF;
	grid-template-columns: repeat(4,1fr);
	gap: 20px;
}
.footer__logo {
	background: #ffffff;
	padding: 10px;
}
.footer__text-col {
	/*width: 343px;*/
}
</style>
