import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'

Vue.use(VueRouter)

export const PathHome = '/'
export const PathProjects = '/projects'
export const PathLibrary = '/library'
export const PathLk = '/lk'
export const PathNews = '/feed'
export const PathProjectsDetails = '/projects/details/:id'

const routes = [
  {
    path: PathHome,
    name: 'Home',
    component: Home
  },
  {
    path: PathProjects,
    name: 'Project',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/Project.vue')
  },
  {
    path: PathLibrary,
    name: 'Library',
    component: () => import(/* webpackChunkName: "about" */ '../views/library/Library.vue')
  },
  {
    path: PathLk,
    name: 'Lk',
    component: () => import(/* webpackChunkName: "about" */ '../views/lk/Lk.vue')
  },
  {
    path: PathNews,
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/feed/NewsArticle.vue')
  },
  {
    path: PathProjectsDetails,
    name: 'DetailsProject',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/DetailsProject.vue'),
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
