import {Login, Logout} from "@/api/login";
import router, {PathHome} from "@/router";

export const moduleLogin = {
    state: {
        isLoginModalShown: false,
    },
    getters: {
        // isAuthenticated: state => state.accessToken.length > 0,
        getAccessToken: state => state.accessToken,
        getRefreshToken: state => state.refreshToken,
        isLoginModalShown: state => state.isLoginModalShown,
    },
    mutations: {
        showLoginModal(state) {
            state.isLoginModalShown = true
        },
        closeLoginModal(state) {
            state.isLoginModalShown = false
        },
    },
    actions: {
        login(ctx, credentials) {
            return Login(ctx, credentials.login, credentials.password).then(response => {
                ctx.dispatch('GET_UPDATE_ACCESS')
            })
        },
        logout(ctx) {
            Logout(ctx).then(_ => {
                ctx.commit('RESET_STATE')
            }, err => {
                console.log(err)
            })
            if (router.history.current.path === PathHome) {
                return
            }
            router.push({name: 'Home'})
        },
        showLoginModal(ctx) {
            ctx.commit('showLoginModal')
        },
        closeLoginModal(ctx) {
            ctx.commit('closeLoginModal')
        },
    },
}
